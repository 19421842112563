<template>
  <v-footer app inset absolute>
    <span class="pl-2"
      >SAC-Tourenverwaltung &copy; 2017 - {{ currentYear }}, J. Rast / SAC -
      Tödi, Version {{ version }}{{ envInfo }}</span
    >
  </v-footer>
</template>

<script>
export default {
  name: 'Footer',
  data() {
    return {
      currentYear: new Date().getFullYear(), // Should this be a computed prop?
      version: process.env.VUE_APP_VERSION,
    }
  },
  computed: {
    envInfo() {
      if (process.env.NODE_ENV !== 'development') {
        return ''
      }
      return ` -- Env: ${process.env.NODE_ENV}, Breakpoint: ${this.$vuetify.breakpoint.name}`
    },
  },
}
</script>

