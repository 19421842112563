<template>
  <v-navigation-drawer
    clipped
    app
    overflow
    :mobile-breakpoint="960"
    v-model="isExpanded"
    :width="240"
  >
    <!-- Menu Items -->
    <v-list dense>
      <template v-for="(item, idx) in localItems">
        <!-- Navigation Items -->
        <v-list-item
          v-if="item.type == 'item'"
          :key="item.title"
          v-bind="item.props"
          v-on="item.on"
        >
          <v-list-item-action>
            <v-icon :color="item.color">{{ item.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
            <v-list-item-subtitle v-if="item.subtitle">{{
              item.subtitle
            }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <!-- Subheaders -->
        <v-subheader v-else-if="item.type == 'header'" :key="item.title">{{
          item.title
        }}</v-subheader>
        <!-- Dividers -->
        <v-divider v-else-if="item.type == 'divider'" :key="`divider-${idx}`" />
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { canNavigate } from '@/router/helpers'

export default {
  name: 'SideNavigation',
  props: {
    expanded: {
      type: Boolean,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
  },
  computed: {
    isExpanded: {
      get() {
        return this.expanded
      },
      set(newVal) {
        if (this.expanded !== newVal) {
          this.$emit('expanded-changed', newVal)
        }
      },
    },
    localItems() {
      return this.items.filter(item => {
        if (item.props && item.props.to) {
          return canNavigate(this.$router.resolve(item.props.to).route)
        }
        return true
      })
    },
  },
}
</script>
